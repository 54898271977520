import React from 'react';
import { string, number } from 'prop-types';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import pathfinder from '@fiverr-private/pathfinder';
import { sendBigQueryEvent, sendMixPanelEvent } from '../../../utils/biEvents';
import { BQ_CLICKED_GUEST_HOMEPAGE_CATEGORY_IMAGE } from '../../../utils/biEvents/events/bigQueryEvents';
import { MP_CATEGORIES_ICON_CLICKED } from '../../../utils/biEvents/events/mixPanelEvents';

import styles from './index.module.scss';

const getCategoryUrl = ({ categorySlug, subCategorySlug, pos }) => {
    if (subCategorySlug) {
        return pathfinder(
            'short_category_sub_category',
            {
                category_id: categorySlug,
                id: subCategorySlug,
            },
            {
                query: {
                    source: 'hplo_cat_sec_TOP',
                    pos,
                },
            }
        );
    }

    return pathfinder(
        'short_category',
        { id: categorySlug },
        {
            query: {
                source: 'hplo_cat_sec_TOP',
                pos,
            },
        }
    );
};

const getLink = ({ directLink, categorySlug, subCategorySlug, position }) => {
    if (directLink) {
        return `${directLink}?source=hplo_cat_sec_TOP&pos=${position}`;
    }

    return getCategoryUrl({ categorySlug, subCategorySlug, pos: position });
};

const Card = ({ categoryName, categorySlug, subCategorySlug, categoryImage, categoryId, position, directLink }) => {
    const onClick = () => {
        sendMixPanelEvent({
            eventName: MP_CATEGORIES_ICON_CLICKED,
            params: {
                categoryName,
                position,
            },
        });

        sendBigQueryEvent({
            eventName: BQ_CLICKED_GUEST_HOMEPAGE_CATEGORY_IMAGE,
            params: { id: categoryId, name: categoryName, position },
        });
    };

    return (
        <div className={styles.listItem}>
            <a
                className={styles.category}
                href={getLink({ directLink, categorySlug, subCategorySlug, position })}
                onClick={onClick}
            >
                <div className={styles.imageWrapper}>
                    <img src={categoryImage} alt={categoryName} loading="lazy" />
                </div>
                <p className={classNames(styles.title, 'tbody-6')}>
                    <I18n k={`logged_out_homepage.categories.${categoryName}`} />
                </p>
            </a>
        </div>
    );
};

Card.propTypes = {
    categoryName: string,
    categorySlug: string,
    subCategorySlug: string,
    categoryImage: string,
    directLink: string,
    categoryId: number,
    position: number,
};

export default Card;
