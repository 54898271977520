import graphicsDesign from '../../assets/newCategories/graphics-design.svg';
import digitalMarketing from '../../assets/newCategories/digital-marketing.svg';
import programming from '../../assets/newCategories/programming-tech.svg';
import videoAnimation from '../../assets/newCategories/video-animation.svg';
import writingTranslation from '../../assets/newCategories/writing-translation.svg';
import business from '../../assets/newCategories/business.svg';
import consulting from '../../assets/newCategories/consulting.svg';
import musicAudio from '../../assets/newCategories/music-audio.svg';
import aiServices from '../../assets/newCategories/ai-services.svg';

export const categoriesData = [
    {
        categoryName: 'programming_tech',
        categoryId: 10,
        categorySlug: 'programming-tech',
        categoryImage: programming,
    },
    {
        categoryName: 'graphics_design',
        categoryId: 3,
        categorySlug: 'graphics-design',
        categoryImage: graphicsDesign,
    },
    {
        categoryName: 'digital_marketing',
        categoryId: 2,
        categorySlug: 'online-marketing',
        categoryImage: digitalMarketing,
    },
    {
        categoryName: 'writing_translation',
        categoryId: 5,
        categorySlug: 'writing-translation',
        categoryImage: writingTranslation,
    },
    {
        categoryName: 'video_animation',
        categoryId: 20,
        categorySlug: 'video-animation',
        categoryImage: videoAnimation,
    },
    {
        categoryName: 'ai_services',
        categoryImage: aiServices,
        directLink: '/cp/ai-services',
    },
    {
        categoryName: 'music_audio',
        categoryId: 12,
        categorySlug: 'music-audio',
        categoryImage: musicAudio,
    },
    {
        categoryName: 'business',
        categoryId: 8,
        categorySlug: 'business',
        categoryImage: business,
    },
    {
        categoryName: 'consulting',
        categoryImage: consulting,
        directLink: '/cp/consulting-services',
    },
];
