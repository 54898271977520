import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import LazyImage from '../LazyImage';

import imageStyles from '../image.module.scss';

const MAX_IMAGES_IN_VIEWPORT = 3;

// pos is a non-zero based index

interface TouchImageProps {
  subCategoryNameKey: string;
  image: {
    touchSrc: string;
    touchSrcSet: string;
    src: string;
  };
  pos: number;
}

export const TouchImage = (props: TouchImageProps) => {
  const { subCategoryNameKey, image, pos } = props;

  if (pos <= MAX_IMAGES_IN_VIEWPORT) {
    return (
      <img
        className={imageStyles.image}
        alt={translate(subCategoryNameKey)}
        src={image.touchSrc}
        srcSet={image.touchSrcSet}
      />
    );
  }

  return <LazyImage alt={translate(subCategoryNameKey)} src={image.touchSrc} srcSet={image.touchSrcSet} />;
};
