import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { Button } from '@fiverr-private/button';
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '@fiverr-private/icons';
import { breakpointMd, breakpointSm } from '@fiverr-private/sass/helpers';
import SafeComponent from '../shared/SafeComponent';
import { MAX_WIDTH_CONTAINER } from '../../utils/constants';
import { getImpressionEnrichment } from '../../utils/biEvents';
import { BQ_IMP_CATEGORY_ICONS_IS_SHOWN } from '../../utils/biEvents/events/bigQueryEvents';
import Card from './Card';
import { categoriesData } from './config';

import styles from './index.module.scss';

const NewCategories = () => {
    const { isTouch } = getContext();

    const carouselRef = useRef(null);

    const [ref] = useImpressionItem(getImpressionEnrichment({ eventName: BQ_IMP_CATEGORY_ICONS_IS_SHOWN }));

    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const [isHidden, setIsHidden] = useState(true);
    const [hiddenCount, setHiddentCount] = useState(0);

    const toggleHidden = () => setIsHidden((prev) => !prev);

    const onScrollRight = () => {
        carouselRef.current.scrollTo(carouselRef.current.scrollWidth, 0);
    };

    const onScrollLeft = () => {
        carouselRef.current.scrollTo(0, 0);
    };

    const handleScroll = () => {
        const list = carouselRef.current;

        setCanScrollLeft(list.scrollLeft !== 0);
        setCanScrollRight(list.scrollWidth - list.offsetWidth !== list.scrollLeft);
    };

    const windowResize = () => {
        if (window.innerWidth < breakpointSm) {
            return setHiddentCount(3);
        }

        if (window.innerWidth < breakpointMd) {
            return setHiddentCount(1);
        }

        return setHiddentCount(0);
    };

    useEffect(() => {
        const list = carouselRef.current;

        handleScroll();

        list.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            list.removeEventListener('scroll', handleScroll);
        };
    }, [carouselRef]);

    useEffect(() => {
        windowResize();

        window.addEventListener('resize', windowResize);

        return () => {
            window.removeEventListener('resize', windowResize);
        };
    }, []);

    const buttonCopy = isHidden ? 'logged_out_homepage.categories.more' : 'logged_out_homepage.categories.less';

    return (
        <div className={classNames(MAX_WIDTH_CONTAINER, 'sub-categories-section')}>
            <div className={styles.wrapper} ref={ref}>
                <div className={styles.inner} ref={carouselRef}>
                    {canScrollLeft && !isTouch && (
                        <button onClick={onScrollLeft} className={classNames(styles.arrow, styles.arrowLeft)}>
                            <ChevronLeftIcon size={16} shape="circle" color="currentColor" />
                        </button>
                    )}

                    <div className={classNames(styles.list, { [styles.hidden]: hiddenCount && isHidden })}>
                        {categoriesData.map((categoryData, index) => (
                            <Card key={index} data-testid="category-card" position={index + 1} {...categoryData} />
                        ))}
                    </div>

                    {canScrollRight && !isTouch && (
                        <button onClick={onScrollRight} className={classNames(styles.arrow, styles.arrowRight)}>
                            <ChevronRightIcon size={16} shape="circle" color="currentColor" />
                        </button>
                    )}
                </div>
                {!!hiddenCount && (
                    <div className={styles.collapse}>
                        <div className={styles.divider}></div>
                        <Button variant="ghost" onClick={toggleHidden}>
                            <I18n k={buttonCopy} params={{ count: hiddenCount }} />
                            {isHidden ? (
                                <ChevronDownIcon size={12} color="currentColor" />
                            ) : (
                                <ChevronUpIcon size={12} color="currentColor" />
                            )}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export { NewCategories };
export default SafeComponent(NewCategories);
