import categories from '../../assets/fingertips/categories.svg';
import handshake from '../../assets/fingertips/handshake.svg';
import lightning from '../../assets/fingertips/lightning.svg';
import support from '../../assets/fingertips/support.svg';

const fingertipsData = [
    {
        id: 'categories',
        title: 'logged_out_homepage.fingertips.categories.title',
        description: 'logged_out_homepage.fingertips.categories.description',
        image: categories,
    },
    {
        id: 'handshake',
        title: 'logged_out_homepage.fingertips.handshake.title',
        description: 'logged_out_homepage.fingertips.handshake.description',
        image: handshake,
    },
    {
        id: 'lightning',
        title: 'logged_out_homepage.fingertips.lightning.title',
        description: 'logged_out_homepage.fingertips.lightning.description',
        image: lightning,
    },
    {
        id: 'support',
        title: 'logged_out_homepage.fingertips.support.title',
        description: 'logged_out_homepage.fingertips.support.description',
        image: support,
    },
];

export default fingertipsData;
