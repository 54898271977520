import React from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { I18n } from '@fiverr-private/i18n-react';
import { Carousel } from '@fiverr-private/orca';
import SafeComponent from '../shared/SafeComponent';
import { CLICKED_SUBCATEGORY_ARROW, MP_CAROUSEL_CLICKED } from '../../utils/biEvents/events/mixPanelEvents';
import { getImpressionEnrichment, sendMixPanelEvent } from '../../utils/biEvents';
import { MAX_WIDTH_CONTAINER } from '../../utils/constants';
import { BQ_IMP_POPULAR_SERVICES_CAROUSEL_IS_SHOWN } from '../../utils/biEvents/events/bigQueryEvents';
import SubCategory from './SubCategory';

import styles from './index.module.scss';

const NewSubcategoryCarousel = ({ subCategories }) => {
    const { isTouch } = getContext();

    const [ref] = useImpressionItem(getImpressionEnrichment({ eventName: BQ_IMP_POPULAR_SERVICES_CAROUSEL_IS_SHOWN }));

    const enrichedSubcategories = subCategories.map((subCategory) => ({ ...subCategory, isTouch }));

    const handleOnArrowClick = ({ direction }) => {
        sendMixPanelEvent({ eventName: MP_CAROUSEL_CLICKED, params: { element: direction } });
        sendMixPanelEvent({ eventName: CLICKED_SUBCATEGORY_ARROW });
    };

    return (
        <div className={MAX_WIDTH_CONTAINER}>
            <div className={styles.wrapper} ref={ref}>
                <h2 className="h2-title">
                    <I18n k="logged_out_homepage.subcategory_carousel.title" />
                </h2>
                <Carousel
                    swipeMode={isTouch}
                    autoSlidesWidth
                    slidePadding={16}
                    slides={enrichedSubcategories}
                    slideClassName={styles.slide}
                    onArrowClick={handleOnArrowClick}
                    Component={SubCategory}
                    scrollType={Carousel.SCROLL_TYPE.ROW}
                />
            </div>
        </div>
    );
};

NewSubcategoryCarousel.propTypes = {
    subCategories: arrayOf(
        shape({
            name: string,
            subCategoryId: number,
            nestedSubCategoryId: number,
            categorySlug: string,
            subCategorySlug: string,
            nestedSubCategorySlug: string,
            picture: arrayOf(
                shape({
                    srcSet: string,
                    media: string,
                })
            ),
            image: shape({
                src: string,
                touchSrcSet: string,
                touchSrc: string,
            }),
        })
    ),
};

export { NewSubcategoryCarousel };
export default SafeComponent(NewSubcategoryCarousel);
