import React from 'react';
import { fiverr_logomaker_logo as LogoMakerLogo } from '@fiverr-private/icons/logos';
import { Button } from '@fiverr-private/button';
import { I18n } from '@fiverr-private/i18n-react';
import { URI } from '@fiverr-private/futile';
import pathfinder from '@fiverr-private/pathfinder';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { colorGrey1200 } from '@fiverr-private/sass/helpers';
import SafeComponent from '../shared/SafeComponent';
import Image from '../shared/Image';
import { MAX_WIDTH_CONTAINER } from '../../utils/constants';
import { getImpressionEnrichment, sendBigQueryEvent } from '../../utils/biEvents';
import { BQ_CLICKED_LM_BANNER, BQ_IMP_LM_BANNER_IS_SHOWN } from '../../utils/biEvents/events/bigQueryEvents';
import { LOHP_BANNER } from './constants';
import { pictureData, imgSrc } from './config';

import styles from './index.module.scss';

export const getLogoMakerLink = () =>
    new URI(pathfinder('logo_maker_buyer_landing_page')).addParams({ source: LOHP_BANNER }).toString();

const reportBi = () => {
    sendBigQueryEvent({ eventName: BQ_CLICKED_LM_BANNER });
};

const LogoMakerBanner = () => {
    const [ref] = useImpressionItem(getImpressionEnrichment({ eventName: BQ_IMP_LM_BANNER_IS_SHOWN }));

    return (
        <div className={MAX_WIDTH_CONTAINER}>
            <div className={styles.wrapper} ref={ref}>
                <div className={styles.inner}>
                    <div className={styles.content}>
                        <small>
                            <i className="logo-maker-logo">
                                <LogoMakerLogo primaryColor={colorGrey1200} />
                            </i>
                        </small>

                        <h2 className={styles.title}>
                            <I18n k={'logged_out_homepage.logo_maker_banner.full_title'} />
                        </h2>

                        <div className="tbody-3">
                            <I18n k={'logged_out_homepage.logo_maker_banner.description'} />
                        </div>

                        <Button href={getLogoMakerLink()} onClick={reportBi}>
                            <I18n k={'logged_out_homepage.logo_maker_banner.try_logo_maker'} />
                        </Button>
                    </div>

                    <div className={styles.imageWrapper}>
                        <picture className={styles.image}>
                            {pictureData.map((image, index) => (
                                <source key={index} media={image.media} srcSet={image.srcSet} />
                            ))}
                            <Image src={imgSrc} loading="lazy" alt="logged_out_homepage.logo_maker_banner.alt" />
                        </picture>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SafeComponent(LogoMakerBanner);
