(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@fiverr/i18n/singleton"), require("axios"), require("classnames"), require("lodash"), require("lodash/camelCase"), require("lodash/has"), require("lodash/mapKeys"), require("lodash/mapValues"), require("lodash/snakeCase"), require("prop-types"), require("react"), require("react-dom"), require("react-redux"), require("redux"), require("redux-thunk"), require("ua-parser-js"));
	else if(typeof define === 'function' && define.amd)
		define(["@fiverr/i18n/singleton", "axios", "classnames", "lodash", "lodash/camelCase", "lodash/has", "lodash/mapKeys", "lodash/mapValues", "lodash/snakeCase", "prop-types", "react", "react-dom", "react-redux", "redux", "redux-thunk", "ua-parser-js"], factory);
	else if(typeof exports === 'object')
		exports["perseusApp"] = factory(require("@fiverr/i18n/singleton"), require("axios"), require("classnames"), require("lodash"), require("lodash/camelCase"), require("lodash/has"), require("lodash/mapKeys"), require("lodash/mapValues"), require("lodash/snakeCase"), require("prop-types"), require("react"), require("react-dom"), require("react-redux"), require("redux"), require("redux-thunk"), require("ua-parser-js"));
	else
		root["perseusApp"] = factory(root["i18n"], root["axios"], root["classNames"], root["_"], root["_"]["camelCase"], root["_"]["has"], root["_"]["mapKeys"], root["_"]["mapValues"], root["_"]["snakeCase"], root["PropTypes"], root["React17"], root["ReactDOM17"], root["ReactRedux"], root["Redux"], root["ReduxThunk"], root["uaParser"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_jgnj__, __WEBPACK_EXTERNAL_MODULE__jHP__, __WEBPACK_EXTERNAL_MODULE_IX_1__, __WEBPACK_EXTERNAL_MODULE_luq0__, __WEBPACK_EXTERNAL_MODULE_LLFf__, __WEBPACK_EXTERNAL_MODULE_KeIu__, __WEBPACK_EXTERNAL_MODULE_CyH___, __WEBPACK_EXTERNAL_MODULE_j1iO__, __WEBPACK_EXTERNAL_MODULE_EJOo__, __WEBPACK_EXTERNAL_MODULE_JjgO__, __WEBPACK_EXTERNAL_MODULE_yHGu__, __WEBPACK_EXTERNAL_MODULE__4LQx__, __WEBPACK_EXTERNAL_MODULE_LL9j__, __WEBPACK_EXTERNAL_MODULE__8zM4__, __WEBPACK_EXTERNAL_MODULE_R8iZ__, __WEBPACK_EXTERNAL_MODULE_glLg__) => {
return 