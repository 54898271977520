const XLRG_IMAGE = '(min-width: 1160px)';
const LRG_IMAGE = '(min-width: 900px)';
const MED_IMAGE = '(min-width: 600px)';
const SML_IMAGE = '(min-width: 361px)';
const XSML_IMAGE = '(max-width: 360px)'; // this is for Google's Lighthouse

const DPR_1 = 'dpr_1.0';
const DPR_2 = 'dpr_2.0';
const SLIDER_GRID_LRG = '(min-width: 1060px)';
const SLIDER_GRID_MED = '(min-width: 800px)';
const SLIDER_GRID_SML = '(min-width: 600px)';
const SLIDER_GRID_XSML = '(max-width: 599px)';

const LOHP_ROW = 'lohp-row';
const LOHP_INNER_ROW = 'lohp-inner-row';
const MAX_WIDTH_CONTAINER = 'max-width-container';

const LOCALES = {
    en: 'en-US',
    de: 'de-DE',
    es: 'es',
    fr: 'fr-FR',
    it: 'it-IT',
    nl: 'nl-NL',
    pt: 'pt-BR',
};

const LOCALES_LABELS = {
    [LOCALES.en]: 'EN',
    [LOCALES.de]: 'DE',
    [LOCALES.es]: 'ES',
    [LOCALES.fr]: 'FR',
    [LOCALES.it]: 'IT',
    [LOCALES.nl]: 'NL',
    [LOCALES.pt]: 'PT',
};

module.exports = {
    XLRG_IMAGE,
    LRG_IMAGE,
    MED_IMAGE,
    SML_IMAGE,
    XSML_IMAGE,
    DPR_1,
    DPR_2,
    SLIDER_GRID_LRG,
    SLIDER_GRID_MED,
    SLIDER_GRID_SML,
    SLIDER_GRID_XSML,
    LOHP_ROW,
    LOHP_INNER_ROW,
    MAX_WIDTH_CONTAINER,
    LOCALES_LABELS,
    LOCALES,
};
