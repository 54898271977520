import React from 'react';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/fiverr_context';
import SafeComponent from '../../shared/SafeComponent';
import { COMPANY_LOGOS } from './config';

import styles from './index.module.scss';

const TrustedBy = () => (
    <div className={styles.wrapper}>
        <span className={styles.text}>
            <I18n k="logged_out_homepage.trusted_by.title" />:
        </span>
        <ul>
            {COMPANY_LOGOS.map((company) => (
                <li key={company}>
                    <img
                        className={classNames({ [styles.hiddenSmall]: company.displayFromSm })}
                        key={company}
                        src={company.image}
                        alt={company.alt}
                    />
                </li>
            ))}
        </ul>
    </div>
);

export { TrustedBy };
export default SafeComponent(TrustedBy);
