import React from 'react';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { useImpressionItem } from '@fiverr-private/impressionable';
import SafeComponent from '../shared/SafeComponent';
import { MAX_WIDTH_CONTAINER } from '../../utils/constants';
import { getImpressionEnrichment, sendBigQueryEvent } from '../../utils/biEvents';
import { BQ_HOVERED_USP, BQ_IMP_USPSS_IS_SHOWN } from '../../utils/biEvents/events/bigQueryEvents';
import fingertipsData from './config';

import styles from './index.module.scss';

const Fingertips = () => {
    const onHover = () => {
        sendBigQueryEvent({ eventName: BQ_HOVERED_USP });
    };

    const [ref] = useImpressionItem(getImpressionEnrichment({ eventName: BQ_IMP_USPSS_IS_SHOWN }));

    return (
        <div className={MAX_WIDTH_CONTAINER}>
            <div className={styles.wrapper} onMouseEnter={onHover} ref={ref}>
                <h2 className="h2-title">
                    <I18n k="logged_out_homepage.fingertips.title" />
                </h2>

                <ul className={styles.list}>
                    {fingertipsData.map(({ id, title, description, image }) => (
                        <li key={id} className={styles.item}>
                            <div className={styles.header}>
                                <img className={styles.image} src={image} alt={translate(title)} loading="lazy" />
                                <h3 className={styles.title}>{translate(title)}</h3>
                            </div>
                            <p className="tbody-5">{translate(description)}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export { Fingertips };
export default SafeComponent(Fingertips);
