/**
 * Should load function is extracted to own file
 * because webpack tree shaking does not work in an entry file.
 */
import { getGigIds as getBrowseHistory } from '../../utils/browsingHistory';

const MINIMUM_ITEMS = 2;

export const shouldLoadPersonalizedSection = (context) => {
    const { userWasLoggedIn } = context;

    return !userWasLoggedIn && getBrowseHistory().length >= MINIMUM_ITEMS;
};
