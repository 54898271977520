import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { buildSeoMarkup } from './utils';

const Seo = () => {
    const { locale, url } = getContext();

    const seoMarkup = buildSeoMarkup(locale, url);

    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: seoMarkup,
            }}
        />
    );
};

export default Seo;
