const CLICKED_TESTIMONIALS_ARROW = 'Clicked testimonials arrow';
const CLICKED_SUBCATEGORY_ARROW = 'Clicked subcategory arrow';
const CLICKED_POPULAR_SEARCH_TAG = 'Clicked popular search tag';
const CLICKED_BRAND_VIDEO = 'Clicked brand video';
const CLICKED_TESTIMONIALS_VIDEO = 'Clicked testimonials video';
const CLICKED_MADE_ON_FIVERR_ARROW = 'Clicked made on fiverr arrow';
const CLICKED_BANNER_SEARCH = 'Clicked banner search';
const MP_BRAND_SHOW_THUMBNAIL = 'Brand Campaign - Thumbnail shown';
const MP_CLICKED_BRAND_VIDEO_PREVIEW = 'Brand Campaign - Clicked thumbnail video';
const MP_SHOW_BRAND_VIDEO_PLAYER = 'Brand Campaign - Video shown';
const MP_CLOSED_BRAND_VIDEO_PLAYER = 'Brand Campaign - Video closed';
const MP_CLICKED_BRAND_LANDING_PAGE_LINK = 'Brand Campaign - Clicked landing page link';
const MP_CLICKED_TOP_PRIORITY = 'Top Priorities SC clicked';
const MP_SHOW_PAGE_NAME = 'Homepage';
const MP_FAQ_CLICKED = 'FAQ Clicked';
const MP_FIB_CLICK = 'Clicked On FiB Banner';
const MP_CAROUSEL_CLICKED = 'Clicked Popular Services Carousel';
const MP_BRAND_VIDEO_CLICK = 'Clicked brand video';
const MP_CATEGORIES_ICON_CLICKED = 'Clicked Category Icon';

export {
    CLICKED_TESTIMONIALS_ARROW,
    CLICKED_SUBCATEGORY_ARROW,
    CLICKED_POPULAR_SEARCH_TAG,
    CLICKED_BRAND_VIDEO,
    CLICKED_TESTIMONIALS_VIDEO,
    CLICKED_MADE_ON_FIVERR_ARROW,
    CLICKED_BANNER_SEARCH,
    MP_CLICKED_BRAND_VIDEO_PREVIEW,
    MP_SHOW_BRAND_VIDEO_PLAYER,
    MP_BRAND_SHOW_THUMBNAIL,
    MP_CLOSED_BRAND_VIDEO_PLAYER,
    MP_CLICKED_BRAND_LANDING_PAGE_LINK,
    MP_SHOW_PAGE_NAME,
    MP_CLICKED_TOP_PRIORITY,
    MP_FAQ_CLICKED,
    MP_FIB_CLICK,
    MP_CAROUSEL_CLICKED,
    MP_BRAND_VIDEO_CLICK,
    MP_CATEGORIES_ICON_CLICKED,
};
