export const LAZY_COMPONENTS = {
    MADE_ON_FIVERR: 'made_on_fiverr',
    TESTIMONIALS: 'testimonials',
    FIVERR_BUSINESS: 'fiverr_business',
    FIVERR_GUIDES: 'fiverr_guides',
    SIGNUP: 'signup',
    VIDEO: 'video',
    LOGO_MAKER_BANNER: 'logo_maker_banner',
    PERSONALIZED_SECTION_WRAPPER: 'personalized_section_wrapper',
};

export const LOGGED_OUT_HOMEPAGE_ENTRY_NAMES_LOCALES = {
    DE: 'logged_out_homepage_de',
    REVAMP: 'logged_out_homepage_revamp',
};
