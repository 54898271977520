import meta from '../../../assets/companies/meta.svg';
import google from '../../../assets/companies/google.svg';
import netflix from '../../../assets/companies/netflix.svg';
import pg from '../../../assets/companies/pg.svg';
import paypal from '../../../assets/companies/paypal.svg';

export const COMPANY_LOGOS = [
    {
        alt: 'meta',
        image: meta,
    },
    {
        alt: 'Google',
        image: google,
    },
    {
        alt: 'NETFLIX',
        image: netflix,
    },
    {
        alt: 'P&G',
        image: pg,
    },
    {
        alt: 'PayPal',
        image: paypal,
        displayFromSm: true,
    },
];
