import { localStorage } from '@fiverr-private/futile';

const LOCAL_STORAGE_KEY = 'browse-history',
    GIGS_REGEX = /gigs\|\|\|show\|\|\|([\d]+)/;

export const getGigIds = () => getFromLocalStorageByRegex(GIGS_REGEX);

const getFromLocalStorageByRegex = (regexExp) => {
    let results = [];
    const userBrowseHistory = localStorage.get(LOCAL_STORAGE_KEY);

    if (userBrowseHistory) {
        results = userBrowseHistory.reduce((accumulator, data) => {
            const match = data.match(regexExp);
            if (match && match.length > 1) {
                accumulator.push(match[1]);
            }
            return accumulator;
        }, []);
    }

    return results;
};
