import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';
import SearchBar from '@fiverr-private/search_bar/src/components/SearchBar';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n, translate } from '@fiverr-private/i18n-react';
import SafeComponent from '../../shared/SafeComponent';

import styles from './index.module.scss';

const Header = ({ searchPlaceholderKey }) => {
    const { isTouch } = getContext();

    searchPlaceholderKey =
        searchPlaceholderKey ||
        (isTouch ? 'logged_out_homepage.hero.search_mobile' : 'logged_out_homepage.hero.search');

    return (
        <div className={styles.wrapper}>
            <h1 className={classNames(styles.title)}>
                <I18n
                    k="logged_out_homepage.hero.title"
                    templates={{
                        i: (text) => <i className={styles.italic}>{text}</i>,
                    }}
                />
            </h1>

            <SearchBar
                placeholder={translate(searchPlaceholderKey)}
                source="main_banner"
                submitButtonType="button_inside"
            />
        </div>
    );
};

Header.propTypes = {
    searchPlaceholderKey: string,
};

export { Header };
export default SafeComponent(Header);
