import { DEFAULT_LOCALE } from '@fiverr-private/fiverr_context/lib/constants';
import { getContext } from '@fiverr-private/fiverr_context';

const shouldShowLogoMakerBanner = () => {
    const { locale } = getContext();

    return locale === DEFAULT_LOCALE;
};

export default shouldShowLogoMakerBanner;
