import React from 'react';
import classNames from 'classnames';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { MAX_WIDTH_CONTAINER } from '../../utils/constants';
import SafeComponent from '../shared/SafeComponent';
import { getImpressionEnrichment } from '../../utils/biEvents';
import { BQ_IMP_TOP_BANNER_IS_SHOWN } from '../../utils/biEvents/events/bigQueryEvents';
import Header from './Header';

import styles from './index.module.scss';
import TrustedBy from './TrustedBy';

const Hero = () => {
    const [ref] = useImpressionItem(getImpressionEnrichment({ eventName: BQ_IMP_TOP_BANNER_IS_SHOWN }));

    return (
        <div className={classNames(MAX_WIDTH_CONTAINER, styles.container)} ref={ref}>
            <div className={classNames(styles.wrapper, { [styles.withBomba]: true })}>
                <Header />
                <TrustedBy />
            </div>
        </div>
    );
};

export { Hero };
export default SafeComponent(Hero);
