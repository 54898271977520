import React from 'react';
import { logger } from '@fiverr-private/obs';
import { LazyComponent } from '@fiverr-private/orca';
import { metric } from '../prefixedStats';

// eslint-disable-next-line react/display-name
export const lazyImport = (componentName, loader) => (props) =>
    (
        <LazyComponent
            lazyImport={loader}
            onError={(error) => reportLoadingError(componentName, error)}
            fallback={null}
            componentProps={props}
        />
    );

const reportLoadingError = (componentName, error) => {
    metric.count(`lazy_loading.fetch_error.${componentName}`);

    logger.warn(error, {
        description: 'Failed to lazy load asset',
        componentName,
    });
};
