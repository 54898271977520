import React from 'react';
import { bool, object } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { useImpressionManager } from '@fiverr-private/impressionable';
import { lazyImport } from '../../../apps/logged_out_homepage/utils/lazyImport';
import shouldShowLogoMakerBanner from '../../../apps/logged_out_homepage/utils/shouldShowLogoMakerBanner';
import Hero from '../../../apps/logged_out_homepage/components/NewHero';
import Categories from '../../../apps/logged_out_homepage/components/NewCategories';
import Seo from '../../../apps/logged_out_homepage/components/Seo';
import EntryWrapper from '../../../apps/logged_out_homepage/components/NewEntryWrapper';
import SubcategoryCarousel from '../../../apps/logged_out_homepage/components/NewSubcategoryCarousel';
import { getSubCategories } from '../../../apps/logged_out_homepage/components/NewSubcategoryCarousel/config';
import Fingertips from '../../../apps/logged_out_homepage/components/Fingertips';
import LogoMakerBanner from '../../../apps/logged_out_homepage/components/NewLogoMakerBanner';
import { shouldLoadPersonalizedSection } from '../../../apps/logged_out_homepage/components/PersonalizedSectionWrapper/shouldLoad';
import { LAZY_COMPONENTS } from './constants';

const MadeOnFiverr = lazyImport(
  LAZY_COMPONENTS.MADE_ON_FIVERR,
  () => import(/* webpackChunkName: 'MadeOnFiverr' */ '../../../apps/logged_out_homepage/components/NewMadeOnFiverr')
);

const Testimonials = lazyImport(
  LAZY_COMPONENTS.TESTIMONIALS,
  () => import(/* webpackChunkName: 'Testimonials' */ '../../../apps/logged_out_homepage/components/NewTestimonials')
);

const Video = lazyImport(
  LAZY_COMPONENTS.VIDEO,
  () => import(/* webpackChunkName: 'Video' */ '../../../apps/logged_out_homepage/components/Video')
);

const FiverrBusiness = lazyImport(
  LAZY_COMPONENTS.FIVERR_BUSINESS,
  () =>
    import(/* webpackChunkName: 'FiverrBusiness' */ '../../../apps/logged_out_homepage/components/NewFiverrBusiness')
);

const FiverrGuides = lazyImport(
  LAZY_COMPONENTS.FIVERR_GUIDES,
  () => import(/* webpackChunkName: 'FiverrGuides' */ '../../../apps/logged_out_homepage/components/NewFiverrGuides')
);

const Signup = lazyImport(
  LAZY_COMPONENTS.SIGNUP,
  () => import(/* webpackChunkName: 'Signup' */ '../../../apps/logged_out_homepage/components/NewSignup')
);

const PersonalizedSectionWrapper = lazyImport(
  LAZY_COMPONENTS.PERSONALIZED_SECTION_WRAPPER,
  () =>
    import(
      /* webpackChunkName: 'PersonalizedSectionWrapper' */ '../../../apps/logged_out_homepage/components/NewPersonalizedSectionWrapper'
    )
);

const LoggedOutHomepageClient = ({ userWasLoggedIn, bianka, displayBrandCampaign, isInRevampReleaseRollout }) => {
  useImpressionManager();
  const showLogoMakerBanner = shouldShowLogoMakerBanner();
  const { locale } = getContext();

  return (
    <EntryWrapper bianka={bianka} displayBrandCampaign={displayBrandCampaign}>
      <Seo />
      <Hero />
      <Categories isInRevampReleaseRollout={isInRevampReleaseRollout} />
      {shouldLoadPersonalizedSection({ userWasLoggedIn }) && <PersonalizedSectionWrapper />}
      <SubcategoryCarousel subCategories={getSubCategories({ locale })} />
      <Fingertips />
      <Video />
      <FiverrBusiness />
      <Testimonials />
      {showLogoMakerBanner && <LogoMakerBanner />}
      <MadeOnFiverr />
      <FiverrGuides />
      <Signup />
    </EntryWrapper>
  );
};

LoggedOutHomepageClient.propTypes = {
  bianka: object,
  userWasLoggedIn: bool,
  isInRevampReleaseRollout: bool,
  displayBrandCampaign: bool,
};

export default LoggedOutHomepageClient;
