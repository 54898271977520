import pathfinder from '@fiverr-private/pathfinder';

export const getSubCategoryUrl = ({ categorySlug, subCategorySlug, params }) =>
    pathfinder(
        'short_category_sub_category',
        {
            category_id: categorySlug,
            id: subCategorySlug,
        },
        { query: params }
    );

export const getNestedSubCategoryUrl = ({ categorySlug, subCategorySlug, nestedSubCategorySlug, params }) =>
    pathfinder(
        'short_category_nested_sub_category',
        {
            category_id: categorySlug,
            id: subCategorySlug,
            nested_sub_category_id: nestedSubCategorySlug,
        },
        { query: params }
    );

export const getStoreUrl = (storeName, params) => pathfinder('store', { store_name: storeName }, { query: params });

/**
 * Get Fiverr search url
 * @param {object} searchParams. should include query value
 * **/
export const getSearchUrl = (searchParams) =>
    pathfinder(
        'search_gigs',
        {},
        {
            query: searchParams,
        }
    );

export const getContentPageUrl = ({ pageSlug, params }) =>
    pathfinder('content_pages_page', { page_slug: pageSlug }, { query: params });
