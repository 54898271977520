/**
 * Maps between a Fiverr locale to how it should be presented SEO wise.
 *
 * The reason we need this is that some locales differ between
 * how they are defined in Fiverr and how they are exposed to external
 * web crawlers (For example `es` should be exposed as `es-ES`)
 *
 * @type {Object}
 */
export const SEO_LOCALES = {
    es: 'es-ES',
};
