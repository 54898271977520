import { DPR_1, DPR_2 } from '../constants';

const CLOUDINARY_PATH = 'https://fiverr-res.cloudinary.com';
const ASSET_PATH = 'v1/attachments/generic_asset/asset'; /* Houston upload path */

const imgPathWithTransforms = ({ width, dpr, img }) => {
    const imgTransform = `q_auto,f_auto,w_${width},${dpr}`;

    return `${CLOUDINARY_PATH}/${imgTransform}/${ASSET_PATH}/${img}`;
};

const imgSrcSet = ({ width, img }) => `${imgPathWithTransforms({ width, dpr: DPR_1, img })} 1x`;
const imgSrcSetRetina = ({ width, img }) => `${imgPathWithTransforms({ width, dpr: DPR_2, img })} 2x`;

const imgSrcSetFull = ({ width, img, retinaImg }) =>
    `${imgSrcSet({ width, img })}, ${imgSrcSetRetina({ width, img: retinaImg ? retinaImg : img })}`;

export { imgPathWithTransforms, imgSrcSet, imgSrcSetRetina, imgSrcSetFull };
