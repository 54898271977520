import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import 'lazysizes';

import styles from '../image.module.scss';

const LazyImage = ({ alt, src, srcSet }) => (
    <img alt={alt} data-src={src} data-srcset={srcSet} className={classNames(styles.image, 'lazyload')} />
);

LazyImage.propTypes = {
    alt: string,
    src: string,
    srcSet: string,
};

export default LazyImage;
