import { imgPathWithTransforms, imgSrcSetFull } from '../../utils/image';
import { DPR_1, LRG_IMAGE, MED_IMAGE, SML_IMAGE, XSML_IMAGE } from '../../utils/constants';
import { XSMALL_WIDTH, SMALL_WIDTH, MEDIUM_WIDTH, LARGE_WIDTH, IMAGE_PATH } from './constants';

export const pictureData = [
    {
        srcSet: imgSrcSetFull({ width: LARGE_WIDTH, img: IMAGE_PATH }),
        media: LRG_IMAGE,
    },
    {
        srcSet: imgSrcSetFull({ width: MEDIUM_WIDTH, img: IMAGE_PATH }),
        media: MED_IMAGE,
    },
    {
        srcSet: imgSrcSetFull({ width: SMALL_WIDTH, img: IMAGE_PATH }),
        media: SML_IMAGE,
    },
    {
        srcSet: imgSrcSetFull({ width: XSMALL_WIDTH, img: IMAGE_PATH }),
        media: XSML_IMAGE,
    },
];

export const imgSrc = imgPathWithTransforms({ width: LARGE_WIDTH, dpr: DPR_1, img: IMAGE_PATH });
