import React, { Component } from 'react';
import ErrorBoundary from './ErrorBoundary';

const SafeComponentWrapper = (WrappedComponent) => {
    // eslint-disable-next-line react/prefer-stateless-function
    class SafeComponent extends Component {
        render() {
            return (
                <ErrorBoundary>
                    <WrappedComponent {...this.props} />
                </ErrorBoundary>
            );
        }
    }

    return SafeComponent;
};

export default SafeComponentWrapper;
