import React from 'react';
import { string } from 'prop-types';
import { I18nProvider } from '@fiverr-private/fiverr_context';

const Image = ({ alt = '', ...props }) => (
    <I18nProvider>{({ i18n }) => <img alt={i18n.t(alt)} {...props} />}</I18nProvider>
);

Image.propTypes = {
    alt: string.isRequired,
};

export default Image;
